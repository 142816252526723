// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4vp1T{position:relative;min-height:350px;background:#181716;transition:background .3s}@media screen and (min-width:375px){._4vp1T{padding:0 8px}}@media screen and (min-width:400px){._4vp1T{padding:0 16px}}._4vp1T.qoxMa{background-color:#181716}.BslN-{padding:24px 0 16px;border-bottom:1px solid #2d2d2d;margin:0 16px}@media screen and (min-width:400px){.BslN-{padding-bottom:24px}}.IHwOE{display:block;padding:0 16px 16px;margin:0;color:#fff;font-family:\"Merriweather\",serif;font-size:26px;font-weight:700;line-height:30px}.xN7rk{padding:0;margin:0;list-style:none}.bgAvs{cursor:pointer}.H22gF{min-height:44px;padding-top:10px;padding-bottom:2px}.H22gF:empty{min-height:16px;padding:0}.Utfqm{display:block;padding:0 0 16px;border-bottom:1px solid #2d2d2d;margin:0 16px;color:#989898;font-size:16px;font-weight:400;line-height:22px}.Utfqm>span{-webkit-line-clamp:3;-webkit-box-orient:vertical;display:block;display:-webkit-box;overflow:hidden;max-height:66px}.bgAvs:last-child .Utfqm{border-bottom:0}.vBssR{min-height:32px}.tdXpv,.NOPxf{padding:24px 0;border-top:1px solid #2d2d2d;margin:0 16px}.NOPxf svg{margin-left:4px;transform:translateY(1px)}.tdXpv{color:#989898;font-size:13px;line-height:20px}.edeJV{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_4vp1T",
	"withData": "qoxMa",
	"addon": "BslN-",
	"title": "IHwOE",
	"list": "xN7rk",
	"item": "bgAvs",
	"itemHeader": "H22gF",
	"description": "Utfqm",
	"footer": "vBssR",
	"comment": "tdXpv",
	"readMore": "NOPxf",
	"loader": "edeJV"
};
module.exports = ___CSS_LOADER_EXPORT___;
