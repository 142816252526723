import { computed, defineComponent, ref, useCssModule } from '@nuxtjs/composition-api';
import { DEFAULT_OPTIONS } from '.';
import { getArticleInfo } from '~/utils';
export default defineComponent({
    name: 'CardStoryMobile',
    props: {
        data: {
            default: () => [],
            type: Array,
        },
        options: {
            default: () => ({}),
            type: Object,
        },
        size: {
            required: true,
            type: Object,
        },
    },
    setup(props, { root }) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const slice = ref(4);
        /**
         * Current options.
         */
        const currentOptions = computed(() => {
            return {
                ...DEFAULT_OPTIONS,
                ...props.options,
            };
        });
        const entries = ['Сюжет', ...currentOptions.value.entries];
        /**
         * Formatted items
         */
        const items = computed(() => {
            return props.data.map(({ data }) => {
                const { id, title } = data;
                return {
                    id,
                    title,
                    ...getArticleInfo.call(root, data),
                };
            });
        });
        const isFolded = computed(() => {
            return (items.value || []).length > slice.value;
        });
        const title = computed(() => {
            let attrs = {};
            let component = 'div';
            if (currentOptions.value.moreTo) {
                attrs = {
                    to: currentOptions.value.moreTo,
                };
                component = 'NLink';
            }
            return {
                attrs,
                component,
            };
        });
        function unfold() {
            slice.value = items.value.length;
        }
        return {
            css,
            currentOptions,
            entries,
            isFolded,
            items,
            title,
            slice,
            unfold,
        };
    },
});
